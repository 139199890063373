


































import { Component, Vue } from 'vue-property-decorator'
import PrivacyContent from '@/components/info/PrivacyContent.vue'

@Component<PrivacyIndex>({
  components: { PrivacyContent }
})
export default class PrivacyIndex extends Vue {
  get xs (): boolean {
    return this.$vuetify.breakpoint.xs
  }
}
